/* eslint-disable no-irregular-whitespace */
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { submitComeDineWithMeResult } from '../../actions/actions.ts';
import { IState } from '../../models/State.ts';

const ComeDineWithMeResults = () => {
   const dispatch = useDispatch();
   const name = useSelector((state: IState) => state.name);

   const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(submitComeDineWithMeResult(e.target.value));
   };

   return (
      <div>
         <div style={{ backgroundColor: '#f7fcfc', padding: '20px', borderBottom: '1px solid lightgray' }}>
            <Helmet>
               <title>Submit Come Dine With Me Results</title>
            </Helmet>
            Search: <input style={{ marginLeft: '5px', marginRight: '10px' }} type='text' onChange={onChangeName} value={name}></input>
            <Marquee autoFill style={{ color: 'lightgray', marginRight: '10px', opacity: '50%' }}>
               In order to become the perfect dinner party host, each participant takes turn during the week to cook and entertain the rest.
               The rivals, meanwhile, snoop around the house and award points in the end. 
            </Marquee>
         </div>
         <div style={{ margin: '20px' }}></div>
      </div>
   );
};

export default ComeDineWithMeResults;
