import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import unzoomedImage from './unzoomed.png';

const Daezoomed = () => {
   const location = useLocation();

   useEffect(() => {
      if (location.pathname === '/daezoomed' || location.pathname === '/unzoomed' || location.pathname === '/unzoomed.com') {
         window.location.href = 'https://www.unzoomed.com/de';
      }
   }, [location]);

   return (
      <div>
         <Helmet>
            <title>Unzoomed</title>
            <meta property='og:title' content='Unzoomed' />
            <meta property='og:type' content='website' />
            <meta property='og:image' content='https://www.martymc.fyi/unzoomed.png' />
         </Helmet>
         <img src={unzoomedImage} alt='Unzoomed' />
         Unzoomed
      </div>
   );
};

export default Daezoomed;
