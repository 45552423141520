import { configureStore } from '@reduxjs/toolkit';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import './index.css';
import { generalReducer } from './reducers/reducers.ts';
import reportWebVitals from './reportWebVitals.ts';

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);
const store = configureStore({ reducer: generalReducer });

root.render(
   <React.StrictMode>
      <Provider store={store}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </Provider>
      <Analytics />
      <SpeedInsights />
   </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
