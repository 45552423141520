import { UPDATE_COME_DINE_WITH_ME_RESULT } from '../actions/actions.ts';
import { IState } from '../models/State.ts';

export const initialState: IState = { name: '' };

export const generalReducer = (state: IState = initialState, action) => {
   console.log('action', action);
   switch (action.type) {
      case UPDATE_COME_DINE_WITH_ME_RESULT:
         return { ...state, name: action.payload.name };
      default:
         return state;
   }
};
